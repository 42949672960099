module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-882b37f6cd/0/cache/gatsby-plugin-manifest-npm-5.10.0-80908bca4d-9f67653e23.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"flsilva.com","short_name":"flsilva.com","start_url":"/","background_color":"#f5f5f5","theme_color":"#f5f5f5","display":"standalone","icon":"src/images/icon.svg","cache_busting_mode":"none","theme_color_in_head":false,"crossOrigin":"use-credentials","legacy":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-6608e9c719/0/cache/gatsby-plugin-offline-npm-6.13.1-f6177c3827-dada4b2691.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"skipWaiting":true}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-7d13e03018/0/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-fc1032bb41.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-1b91dc48ea/0/cache/gatsby-remark-autolink-headers-npm-6.13.1-0091404466-ae22112494.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"className":"autolink-header-anchor","maintainCase":false,"removeAccents":true},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-fbcd858cd8/0/cache/gatsby-plugin-google-tagmanager-npm-5.13.1-f82e0c3055-e4d7e6e0c9.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MZ5B7LWK","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-0b67ff07eb/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
