exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-antigen-index-tsx": () => import("./../../../src/pages/blog/antigen/index.tsx" /* webpackChunkName: "component---src-pages-blog-antigen-index-tsx" */),
  "component---src-pages-blog-git-index-tsx": () => import("./../../../src/pages/blog/git/index.tsx" /* webpackChunkName: "component---src-pages-blog-git-index-tsx" */),
  "component---src-pages-blog-homebrew-index-tsx": () => import("./../../../src/pages/blog/homebrew/index.tsx" /* webpackChunkName: "component---src-pages-blog-homebrew-index-tsx" */),
  "component---src-pages-blog-https-index-tsx": () => import("./../../../src/pages/blog/https/index.tsx" /* webpackChunkName: "component---src-pages-blog-https-index-tsx" */),
  "component---src-pages-blog-hybrid-web-app-model-index-tsx": () => import("./../../../src/pages/blog/hybrid-web-app-model/index.tsx" /* webpackChunkName: "component---src-pages-blog-hybrid-web-app-model-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-lets-encrypt-index-tsx": () => import("./../../../src/pages/blog/lets-encrypt/index.tsx" /* webpackChunkName: "component---src-pages-blog-lets-encrypt-index-tsx" */),
  "component---src-pages-blog-linux-index-tsx": () => import("./../../../src/pages/blog/linux/index.tsx" /* webpackChunkName: "component---src-pages-blog-linux-index-tsx" */),
  "component---src-pages-blog-macos-index-tsx": () => import("./../../../src/pages/blog/macos/index.tsx" /* webpackChunkName: "component---src-pages-blog-macos-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-components-boxes-warning-box-no-run-dollar-sign-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/components/boxes/WarningBoxNoRunDollarSign.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-components-boxes-warning-box-no-run-dollar-sign-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-beyond-goals-doerrs-okrs-for-high-performance-teams-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/beyond-goals-doerrs-okrs-for-high-performance-teams/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-beyond-goals-doerrs-okrs-for-high-performance-teams-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-building-opentask-nextjs-app-router-react-server-components-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/building-opentask-nextjs-app-router-react-server-components/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-building-opentask-nextjs-app-router-react-server-components-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-basic-commands-explained-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-basic-commands-explained/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-basic-commands-explained-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-branching-and-merging-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-branching-and-merging/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-branching-and-merging-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-remote-and-tracking-branches-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-remote-and-tracking-branches/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-remote-and-tracking-branches-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-tagging-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-tagging/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-tagging-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-workflow-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-workflow/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-workflow-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-configure-a-website-on-nginx-and-linux-tutorial-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-configure-a-website-on-nginx-and-linux-tutorial/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-configure-a-website-on-nginx-and-linux-tutorial-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-generate-use-ssh-keys-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-generate-use-ssh-keys-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-generate-use-ssh-keys-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-and-use-homebrew-on-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-and-use-homebrew-on-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-and-use-homebrew-on-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-command-line-developer-tools-on-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-command-line-developer-tools-on-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-command-line-developer-tools-on-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-git-with-homebrew-on-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-git-with-homebrew-on-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-git-with-homebrew-on-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-nginx-tutorial-ubuntu-and-debian-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-nginx-tutorial-ubuntu-and-debian/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-nginx-tutorial-ubuntu-and-debian-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-nodejs-nvm-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-nodejs-nvm-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-nodejs-nvm-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-use-antigen-oh-my-zsh-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-use-antigen-oh-my-zsh-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-use-antigen-oh-my-zsh-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-password-protect-file-directory-nginx-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-password-protect-file-directory-nginx/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-password-protect-file-directory-nginx-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-report-bugs-effectively-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-report-bugs-effectively/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-report-bugs-effectively-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-securely-set-up-vps-server-tutorial-ubuntu-and-debian-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-securely-set-up-vps-server-tutorial-ubuntu-and-debian/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-securely-set-up-vps-server-tutorial-ubuntu-and-debian-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-set-up-mac-software-development-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-set-up-mac-software-development/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-set-up-mac-software-development-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-sign-git-commits-ssh-keys-macos-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-sign-git-commits-ssh-keys-macos/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-sign-git-commits-ssh-keys-macos-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-wildcard-https-tls-linux-lets-encrypt-nginx-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-wildcard-https-tls-linux-lets-encrypt-nginx/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-wildcard-https-tls-linux-lets-encrypt-nginx-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introducing-git-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/introducing-git/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introducing-git-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-nodejs-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/introduction-to-nodejs/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-nodejs-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-nosql-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/introduction-to-nosql/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-nosql-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-uml-concepts-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/introduction-to-uml-concepts/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-uml-concepts-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-nexar-application-architecture-for-nextjs-app-router-apps-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/nexar-application-architecture-for-nextjs-app-router-apps/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-nexar-application-architecture-for-nextjs-app-router-apps-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-react-server-components-and-a-new-hybrid-web-app-model-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/react-server-components-and-a-new-hybrid-web-app-model/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-react-server-components-and-a-new-hybrid-web-app-model-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-software-testing-in-the-real-world-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/software-testing-in-the-real-world/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-software-testing-in-the-real-world-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-testing-is-not-quality-assurance-qa-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/testing-is-not-quality-assurance-qa/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-testing-is-not-quality-assurance-qa-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-welcome-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/welcome/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-welcome-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-are-https-tls-certificates-and-lets-encrypt-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-are-https-tls-certificates-and-lets-encrypt/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-are-https-tls-certificates-and-lets-encrypt-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-a-product-specification-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-is-a-product-specification/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-a-product-specification-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-software-architecture-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-is-software-architecture/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-software-architecture-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-software-testing-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-is-software-testing/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-software-testing-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-really-is-a-software-bug-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-really-is-a-software-bug/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-really-is-a-software-bug-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-bienal-2012-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/bienal-2012/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-bienal-2012-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-conspiracao-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/conspiracao/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-conspiracao-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-fanswell-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/fanswell/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-fanswell-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-imprint-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/imprint/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-imprint-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-pearson-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/pearson/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-pearson-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-pepsi-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/pepsi/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-pepsi-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-schin-caiu-na-rede-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/schin-caiu-na-rede/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-schin-caiu-na-rede-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-schin-casa-com-tudo-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/schin-casa-com-tudo/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-schin-casa-com-tudo-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-ultraeco-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/ultraeco/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-ultraeco-index-mdx" */),
  "component---src-pages-blog-nexar-index-tsx": () => import("./../../../src/pages/blog/nexar/index.tsx" /* webpackChunkName: "component---src-pages-blog-nexar-index-tsx" */),
  "component---src-pages-blog-nextjs-index-tsx": () => import("./../../../src/pages/blog/nextjs/index.tsx" /* webpackChunkName: "component---src-pages-blog-nextjs-index-tsx" */),
  "component---src-pages-blog-nginx-index-tsx": () => import("./../../../src/pages/blog/nginx/index.tsx" /* webpackChunkName: "component---src-pages-blog-nginx-index-tsx" */),
  "component---src-pages-blog-nodejs-index-tsx": () => import("./../../../src/pages/blog/nodejs/index.tsx" /* webpackChunkName: "component---src-pages-blog-nodejs-index-tsx" */),
  "component---src-pages-blog-nosql-index-tsx": () => import("./../../../src/pages/blog/nosql/index.tsx" /* webpackChunkName: "component---src-pages-blog-nosql-index-tsx" */),
  "component---src-pages-blog-nvm-index-tsx": () => import("./../../../src/pages/blog/nvm/index.tsx" /* webpackChunkName: "component---src-pages-blog-nvm-index-tsx" */),
  "component---src-pages-blog-ohmyzsh-index-tsx": () => import("./../../../src/pages/blog/ohmyzsh/index.tsx" /* webpackChunkName: "component---src-pages-blog-ohmyzsh-index-tsx" */),
  "component---src-pages-blog-opentask-index-tsx": () => import("./../../../src/pages/blog/opentask/index.tsx" /* webpackChunkName: "component---src-pages-blog-opentask-index-tsx" */),
  "component---src-pages-blog-react-index-tsx": () => import("./../../../src/pages/blog/react/index.tsx" /* webpackChunkName: "component---src-pages-blog-react-index-tsx" */),
  "component---src-pages-blog-security-index-tsx": () => import("./../../../src/pages/blog/security/index.tsx" /* webpackChunkName: "component---src-pages-blog-security-index-tsx" */),
  "component---src-pages-blog-software-architecture-index-tsx": () => import("./../../../src/pages/blog/software-architecture/index.tsx" /* webpackChunkName: "component---src-pages-blog-software-architecture-index-tsx" */),
  "component---src-pages-blog-software-testing-index-tsx": () => import("./../../../src/pages/blog/software-testing/index.tsx" /* webpackChunkName: "component---src-pages-blog-software-testing-index-tsx" */),
  "component---src-pages-blog-ssh-keys-index-tsx": () => import("./../../../src/pages/blog/ssh-keys/index.tsx" /* webpackChunkName: "component---src-pages-blog-ssh-keys-index-tsx" */),
  "component---src-pages-blog-sysadmin-index-tsx": () => import("./../../../src/pages/blog/sysadmin/index.tsx" /* webpackChunkName: "component---src-pages-blog-sysadmin-index-tsx" */),
  "component---src-pages-blog-tls-index-tsx": () => import("./../../../src/pages/blog/tls/index.tsx" /* webpackChunkName: "component---src-pages-blog-tls-index-tsx" */),
  "component---src-pages-blog-uml-index-tsx": () => import("./../../../src/pages/blog/uml/index.tsx" /* webpackChunkName: "component---src-pages-blog-uml-index-tsx" */),
  "component---src-pages-case-studies-accelerate-your-startup-success-nextjs-app-router-index-tsx": () => import("./../../../src/pages/case-studies/accelerate-your-startup-success-nextjs-app-router/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-accelerate-your-startup-success-nextjs-app-router-index-tsx" */),
  "component---src-pages-full-stack-index-tsx": () => import("./../../../src/pages/full-stack/index.tsx" /* webpackChunkName: "component---src-pages-full-stack-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mvp-index-tsx": () => import("./../../../src/pages/mvp/index.tsx" /* webpackChunkName: "component---src-pages-mvp-index-tsx" */),
  "component---src-pages-nextjs-index-tsx": () => import("./../../../src/pages/nextjs/index.tsx" /* webpackChunkName: "component---src-pages-nextjs-index-tsx" */),
  "component---src-pages-open-source-index-tsx": () => import("./../../../src/pages/open-source/index.tsx" /* webpackChunkName: "component---src-pages-open-source-index-tsx" */),
  "component---src-pages-opentask-showcase-index-tsx": () => import("./../../../src/pages/opentask-showcase/index.tsx" /* webpackChunkName: "component---src-pages-opentask-showcase-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-components-boxes-warning-box-no-run-dollar-sign-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/components/boxes/WarningBoxNoRunDollarSign.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-components-boxes-warning-box-no-run-dollar-sign-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-beyond-goals-doerrs-okrs-for-high-performance-teams-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/beyond-goals-doerrs-okrs-for-high-performance-teams/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-beyond-goals-doerrs-okrs-for-high-performance-teams-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-building-opentask-nextjs-app-router-react-server-components-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/building-opentask-nextjs-app-router-react-server-components/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-building-opentask-nextjs-app-router-react-server-components-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-basic-commands-explained-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-basic-commands-explained/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-basic-commands-explained-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-branching-and-merging-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-branching-and-merging/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-branching-and-merging-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-remote-and-tracking-branches-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-remote-and-tracking-branches/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-remote-and-tracking-branches-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-tagging-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-tagging/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-tagging-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-workflow-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/git-workflow/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-git-workflow-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-configure-a-website-on-nginx-and-linux-tutorial-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-configure-a-website-on-nginx-and-linux-tutorial/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-configure-a-website-on-nginx-and-linux-tutorial-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-generate-use-ssh-keys-macos-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-generate-use-ssh-keys-macos/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-generate-use-ssh-keys-macos-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-and-use-homebrew-on-macos-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-and-use-homebrew-on-macos/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-and-use-homebrew-on-macos-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-command-line-developer-tools-on-macos-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-command-line-developer-tools-on-macos/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-command-line-developer-tools-on-macos-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-git-with-homebrew-on-macos-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-git-with-homebrew-on-macos/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-git-with-homebrew-on-macos-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-nginx-tutorial-ubuntu-and-debian-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-nginx-tutorial-ubuntu-and-debian/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-nginx-tutorial-ubuntu-and-debian-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-nodejs-nvm-macos-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-nodejs-nvm-macos/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-nodejs-nvm-macos-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-use-antigen-oh-my-zsh-macos-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-install-use-antigen-oh-my-zsh-macos/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-install-use-antigen-oh-my-zsh-macos-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-password-protect-file-directory-nginx-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-password-protect-file-directory-nginx/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-password-protect-file-directory-nginx-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-report-bugs-effectively-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-report-bugs-effectively/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-report-bugs-effectively-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-securely-set-up-vps-server-tutorial-ubuntu-and-debian-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-securely-set-up-vps-server-tutorial-ubuntu-and-debian/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-securely-set-up-vps-server-tutorial-ubuntu-and-debian-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-set-up-mac-software-development-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-set-up-mac-software-development/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-set-up-mac-software-development-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-sign-git-commits-ssh-keys-macos-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-sign-git-commits-ssh-keys-macos/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-sign-git-commits-ssh-keys-macos-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-wildcard-https-tls-linux-lets-encrypt-nginx-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/how-to-wildcard-https-tls-linux-lets-encrypt-nginx/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-how-to-wildcard-https-tls-linux-lets-encrypt-nginx-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introducing-git-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/introducing-git/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introducing-git-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-nodejs-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/introduction-to-nodejs/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-nodejs-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-nosql-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/introduction-to-nosql/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-nosql-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-uml-concepts-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/introduction-to-uml-concepts/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-introduction-to-uml-concepts-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-nexar-application-architecture-for-nextjs-app-router-apps-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/nexar-application-architecture-for-nextjs-app-router-apps/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-nexar-application-architecture-for-nextjs-app-router-apps-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-react-server-components-and-a-new-hybrid-web-app-model-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/react-server-components-and-a-new-hybrid-web-app-model/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-react-server-components-and-a-new-hybrid-web-app-model-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-software-testing-in-the-real-world-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/software-testing-in-the-real-world/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-software-testing-in-the-real-world-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-testing-is-not-quality-assurance-qa-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/testing-is-not-quality-assurance-qa/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-testing-is-not-quality-assurance-qa-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-welcome-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/welcome/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-welcome-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-are-https-tls-certificates-and-lets-encrypt-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-are-https-tls-certificates-and-lets-encrypt/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-are-https-tls-certificates-and-lets-encrypt-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-a-product-specification-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-is-a-product-specification/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-a-product-specification-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-software-architecture-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-is-software-architecture/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-software-architecture-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-software-testing-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-is-software-testing/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-is-software-testing-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-really-is-a-software-bug-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/blog/what-really-is-a-software-bug/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-blog-what-really-is-a-software-bug-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-bienal-2012-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/bienal-2012/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-bienal-2012-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-conspiracao-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/conspiracao/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-conspiracao-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-fanswell-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/fanswell/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-fanswell-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-imprint-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/imprint/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-imprint-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-pearson-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/pearson/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-pearson-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-pepsi-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/pepsi/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-pepsi-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-schin-caiu-na-rede-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/schin-caiu-na-rede/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-schin-caiu-na-rede-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-schin-casa-com-tudo-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/schin-casa-com-tudo/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-schin-casa-com-tudo-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-ultraeco-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/flsilva/no-backup/repos/my-apps/flsilva.com/src/data/portfolio/ultraeco/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-src-data-portfolio-ultraeco-index-mdx" */),
  "component---src-pages-react-index-tsx": () => import("./../../../src/pages/react/index.tsx" /* webpackChunkName: "component---src-pages-react-index-tsx" */),
  "component---src-pages-saas-index-tsx": () => import("./../../../src/pages/saas/index.tsx" /* webpackChunkName: "component---src-pages-saas-index-tsx" */),
  "component---src-pages-startup-index-tsx": () => import("./../../../src/pages/startup/index.tsx" /* webpackChunkName: "component---src-pages-startup-index-tsx" */)
}

